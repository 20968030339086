import { rgbToHex } from "@material-ui/core";

export const pages: { text: string; link: string }[] = [
  {
    text: "Home",
    link: "/",
  },
  {
    text: "About Us",
    link: "/about",
  },
  {
    text: "Services",
    link: "/services",
  },
  // {
  //   text: "Our Fleet", SECOND RELEASE
  //   link: "/fleet",
  // },
  {
    text: "Contact Us",
    link: "/contact",
  },
  // {
  //   text: "Book Now", THIRD RELEASE
  //   link: "/book",
  // },
];

export const theme = {
  primaryColor: "#428AC6",
  primaryText: "black",
};

export const homeBannerText =
  "Your favorite luxury limousine service for a ride to remember. Proudly serving the Durham region and the rest of the GTA for over 20 years.";
export const aboutBannerText = "Luxury. Comfort. Excellence.";
export const fleetBannerText =
  "Arrive in style with the perfect choice of vehicle";
export const serviceBannerText =
  "From weddings, airport transfers, special occasions and more";
export const contactBannerText =
  "See why Memories Limousine is the best limousine service in the GTA";

export const missionText =
  "Memories Limousine is a luxury limousine service company operating out of Whitby and providing clients from the Durham region and the rest of the GTA with limousine rental services and highly professional chauffeurs for a prestigious and comfortable experience. We are one of the leading limousine service companies in Durham, with over 20 years of experience in offering safe and reliable transportation services to clients to and from airports, business meetings, and events.";
export const homeText1 =
  "We are open 24/7. Our sophisticated and professional limo will reach your destination on time. Whether it is a ride to the airport, a night out or a special occasion we have you covered. No need to worry about cancellations or delays. We keep a good track of timings without letting you wait for a second.";
export const aboutText2 =
  "Our services are built on honesty and trust to provide our clients with affordable limousine services without compromising quality. Chauffeurs at Memories Limousine strive to be courteous and accommodating at all times. Besides providing you a smooth and safe ride, our chauffeurs are well-aware of all traveling routes in Durham and the rest of the GTA and can help plan your routes efficiently. Working in this industry for many years, each of our chauffeurs will go the extra mile to personalize your experience.";
export const homeText2 =
  "Whether it’s business or pleasure, we’ll take care of everything so you don’t have to. It is our firm conviction you can get a high-quality luxury experience at the most affordable prices. Memories Limousine has earned a reputation of trustworthiness and excellence in Durham Region as proven by our repeat clients.";

export const weddingText =
  "When you and your fiance embark on your new life together, you’ll surely want to do it in style. Our wedding packages offer full VIP treatment for your special day including: red carpet services, limo décor, and a bubbly surprise!";
export const promText =
  "You’ve worked hard, and deserve to night out to celebrate your transition to life’s next big chapter. Our Prom or Graduation packages include all the features that make a high-end party on wheels, so classmates can have a safe and reliable ride home after celebrating";
export const birthdayText =
  "Whether it’s a grand birthday celebration or a small-scale birthday party with close friends, our multifaced fleet can turn your event into an exceptional celebration.";
export const eventText =
  "Become a VIP when you arrive to your venue with convenience and glamour. A hassle-free option to transport your group to cheer on your favorite sports team, sing-along at a concert or catch a live performance. ";
export const nightOutText =
  "Start the party long before reaching your destination. There is no better or safer way to travel. Our flexible and convenient services can make your excursion a night to remember. ";
export const corporateText =
  "For VIP meetings and event transportation, use our chauffered services to transport your delegates from meetings, to dinners, and accommodation throughout their stay.";
export const wineText =
  "There's no better way to experience Ontario’s award-winning wineries. Indulge in your own fully-customizable tour of Niagara-on-the-Lake, or Prince Edward Country";
export const airportText =
  "Our door-to-door service offers a truly stress-free connection to Toronto’s Pearson International or Billy Bishop Airport. We will handle your luggage and track your flight for a first-class experience";

export const contactSubText =
  "Our Team members would be glad to help with your reservations by phone, online or via email.";

export const covidText =
  "Your well-being and safety is our number one priority. We are taking additional steps to address client safety while aboard one of our luxury vehicles. Drivers are provided masks and gloves for increased passenger protection during transport, and have hand sanitizer available upon request.";
export const covidText2 =
  "Our daily vehicle cleaning protocol includes a full interior disinfecting treatment with alcohol/bleach-based cleansers throughout the day, including in between passenger changes. This prioritizes common touch points such as all door handles, power switches, window sills, arm rests, seat belts, cup holders, and head rests. We continue to monitor federal, provincial and local public health recommendations so that we can make every effort to ensure that our passengers arrive safely and healthy at their destination.";

export const accoladeText1 =
  "Memories Limousine has been serving the Durham Region and the Greater Toronto Area for over two decades with many loyal and satisfied customers";
export const accoladeText2 =
  "You can expect the best with Memories Limousine. Our staff strives to provide the highest level of customer care and we take pride in customizing each service to best suit your needs";
export const accoladeText3 =
  "What you need, exactly when you need it. We offer an impressive fleet at competitive rates designed to save you time so that you can focus on what really matters";
