import React, {useEffect, useState} from "react";
import styled from "styled-components";
import "../../styles/global.css";
import { Link } from "gatsby";
import Logo from '../../images/logo.png';
import {theme} from '../../constants';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import Menu from '../../images/menu.png';
import Close from '../../images/close.png';


const Header = (props: {
  menuOptions: { text: string; link: string }[];
}) => {
  const { menuOptions } = props;

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  }

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  }


  //the first part of the path and only the first part of the path just in case we have nested routes
  const pathName = typeof window !== "undefined" ?  `/${window?.location.pathname.split('/')[1]}` : ''

  return (
    <HeaderContainer>
      <ContentContainer>
      <LogoContainer> 
         {/* <LogoImage src = {Logo}/> */}
      </LogoContainer>
      <LinkContainer>
      <Hidden xsDown>
        {menuOptions.map((menuItem) => (
          <StyledLink to={menuItem.link} selected = {menuItem.link === pathName}> {menuItem.text} </StyledLink>
        ))}
        </Hidden>
        <Hidden smUp >
        <MenuContainer onClick = {handleDrawerOpen}> 
        <MenuImage src = {Menu} alt = "hamburger menu"/>
      </MenuContainer>
           </Hidden>
      </LinkContainer>
      </ContentContainer>
      <Hidden smUp >
      <Drawer anchor="top" open={isDrawerOpen}>
        <DrawerContainer>
        {menuOptions.map((menuItem) => (
          <StyledDrawerLink to={menuItem.link} selected = {menuItem.link === pathName}> {menuItem.text} </StyledDrawerLink>
        ))}
        </DrawerContainer>
      <CloseContainer onClick = {handleDrawerClose}> 
        <CloseImage src = {Close} alt = "close icon"/>
      </CloseContainer>
        </Drawer>
        </Hidden>
    </HeaderContainer>
  );
};

/*--------------Styling-----------------*/

const HeaderContainer = styled.div`
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 80px;
  box-shadow: 0 0 6px -1px rgb(0 0 0 / 20%);
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content:center;
`;

const ContentContainer = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
width: 90%; 
`
const LogoImage = styled.img`
height: 18px;
    width: 175px;
`

const LogoContainer = styled.div`
  font-size: 32px;
  font-weight: 700;
  color: black;
  text-align: center;
  display:flex;
  align-items:center;
  justify-content:center;
`;


const MenuImage = styled.img`
  height: 40px;
  width: 40px;
`

const MenuContainer = styled.div`
  font-size: 32px;
  font-weight: 700;
  color: black;
  text-align: center;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor: pointer;
`

const CloseImage = styled.img`
  height: 18px;
  width: 18px;
`

const CloseContainer = styled.div`
  font-size: 32px;
  font-weight: 700;
  color: black;
  text-align: center;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;

`

const LinkContainer = styled.div`
  display: flex;
`;

const StyledLink = styled(Link)`
  color: black;
  font-size: 16px;
  font-weight: 400;
  padding: 5px 20px;
  text-decoration:${props => props.selected ? 'underline' : 'none'};
  text-underline-offset: ${props => props.selected ? '0.4em' : ''};
  text-decoration-color: ${theme.primaryColor};
`;

const StyledDrawerLink = styled(StyledLink)`
  display: block;
  text-align:center;
  text-decoration: none;
  font-size: 24px;
font-weight: ${props => props.selected ? 600 : 400};
margin:auto;
text-align:center;
margin-top: 20px;
cursor:pointer;
`;

const DrawerContainer = styled.div`

  padding-top: 20px;
  padding-bottom : 60px;
`

const MenuItem = styled.div`
font-size: 24px;
font-weight: 500;
margin:auto;
text-align:center;
margin-top: 20px;
cursor:pointer;
`


export default Header;
